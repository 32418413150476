import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Ensure the correct import path
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function Catalog() {
    const navigate = useNavigate(); // Initialize navigate function
    const [songs, setSongs] = useState([]); // State to store song data
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSongs = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, `songs`));
                const querySnapshot = await getDocs(q);
                const songsData = [];
                querySnapshot.forEach((doc) => {
                    const song = doc.data();
                    // Add the document ID to the song object
                    song.id = doc.id;
                    songsData.push(song);
                });
                // Sort songsData based on createdOn timestamp in descending order
                songsData.sort((a, b) => b.createdOn - a.createdOn);
                setSongs(songsData);
                setLoading(false);
            }
        };
        fetchSongs();
    }, []);
    

    const handleSignOut = async () => {
        const auth = getAuth(); // Get the auth instance
        try {
            await signOut(auth); // Sign out the user
            navigate('/signin'); // Navigate to sign-in page after sign out
        } catch (error) {
            console.error('Error signing out: ', error); // Log any errors
        }
    };

    const handleAddSong = () => {
        navigate('/add-song'); // Navigate to the add-song page
    };

    const editSong = (song) => {
        navigate('/add-song', { state: { song } });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex justify-center flex-col items-center mx-auto pt-4">
            <div className="flex gap-4 mb-20">
                <button className="text-white p-2 bg-blue-500 hover:bg-blue-600 rounded" onClick={handleSignOut}>Sign Out</button>
                <button className="text-black p-2 bg-white hover:bg-gray-200 rounded" onClick={handleAddSong}>+ Add song</button>
            </div>
            {songs.length > 0 ? (
                <div className="flex flex-row flex-wrap justify-center gap-4">
                    {songs.map((song, index) => (
                        <button key={index} onClick={() => editSong(song)}>
                            <img src={song.coverArt} alt="Cover Art" style={{ width: '200px', height: '200px' }} />
                        </button>
                    ))}
                </div>
            ) : (
                <h1 className="text-white">Catalog is empty. Please add a song to begin.</h1>
            )}
        </div>
    );
}

export default Catalog;

import React, { useState } from 'react';
import SocialIcons from './SocialIcons';

function FooterMenu() {
    // Initialize state to manage the email input
    const [email, setEmail] = useState('');

    // Handler to update state based on input changes
    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };
    return (
        <footer className="max-w-screen-xl mx-auto pt-36 pb-16 bg-black text-white flex flex-col items-center">
            <div id="mc_embed_signup">
                <form
                    action="https://gmail.us8.list-manage.com/subscribe/post?u=6d98254ff8712ec9a2780d92c&amp;id=affa86945a&amp;f_id=006065e0f0"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate flex flex-col items-center mb-8 w-80"
                    target="_self">
                    <h1 id="mailing-list" className="text-3xl sm:text-4xl text-center mb-8 font-semibold">MAILING LIST:</h1>
                    <input
                        type="email"
                        value={email} // Set the input's value to the state
                        onChange={handleInputChange} // Update state when input changes
                        name="EMAIL"
                        className="required email bg-black text-white p-2 mb-8 border-white rounded-none border-b w-full outline-none text-xl"
                        required
                    />
                    <div className="clear">
                        <button type="submit" value="SUBSCRIBE" name="subscribe" id="mc-embedded-subscribe"
                            className="bg-white font-semibold text-black p-1 mb-8">SUBSCRIBE</button>
                    </div>
                    <p className="newsletter-desc text-sm font-normal">Sign up for regular updates from Arpita.</p>
                    <p className="newsletter-desc text-sm font-normal">You may withdraw at any time.</p>
                    <p className="newsletter-desc text-sm font-normal mb-16">
                        We do not sell your personal info.
                    </p>

                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                        <input type="text" name="b_6d98254ff8712ec9a2780d92c_affa86945a" tabIndex="-1" value="" />
                    </div>
                </form>
            </div>

            <SocialIcons />

            <h1 className="font-semibold mb-4 mt-10 text-sm">&copy; 2024 ARPITA, LLC ALL RIGHTS RESERVED</h1>
            <a href="https://www.karansingh.co" target="_blank" rel="noopener noreferrer">
                <button className="font-thin">MGMT: KARAN</button>
            </a>
        </footer>
    );
}

export default FooterMenu;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';

// Components
import HomePage from './components/HomePage';
import NavMenu from './components/NavMenu';
import FooterMenu from './components/FooterMenu';
import Bookings from './components/Bookings';
import ThankYou from './components/ThankYou';
import SignIn from './components/SignIn';
import Catalog from './components/Catalog';
import AddSong from './components/AddSong';
import SongPage from './components/SongPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <NavMenu /> {/* NavMenu is always displayed */}
      <div className="content">
        <Routes>
          {/* Public routes that don't require authentication */}
          <Route path="/" element={<HomePage />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/:songName" element={<SongPage />} />

          {/* Protected routes that require authentication */}
          <Route path="/catalog" element={isAuthenticated ? <Catalog /> : <Navigate to="/signin" />} />
          <Route path="/add-song" element={isAuthenticated ? <AddSong /> : <Navigate to="/signin" />} />
        </Routes>

      </div>
      <FooterMenu /> {/* FooterMenu is always displayed */}
    </Router>
  );
}

export default App;
